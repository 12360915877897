:root {
  /* Charte de couleur */

  /* Vert */
  --V_primaryColor: rgb(13, 102, 56);
  --V_darkColor: rgb(8, 65, 35);
  --V_colorOpaque080: rgba(8, 65, 35, .8);
  --V_colorOpaque060: rgba(8, 65, 35, .6);
  --V_colorOpaque030: rgba(8, 65, 35, .3);
  --V_colorOpaque020: rgba(8, 65, 35, .2);
  --V_colorOpaque010: rgba(8, 65, 35, .1);

  /* Orange */
  --O_primaryColor: rgb(255, 125, 18);
  --O_darkColor: rgb(151, 72, 8);
  --O_colorOpaque080: rgba(255, 125, 18, .8);
  --O_colorOpaque060: rgba(255, 125, 18, .6);
  --O_colorOpaque030: rgba(255, 125, 18, .3);
  --O_colorOpaque020: rgba(255, 125, 18, .2);
  --O_colorOpaque010: rgba(255, 125, 18, .1);
  --Blanc: white;
}

footer {
  position: relative;
  left: 0;
  bottom: 0;
  text-align: left;
  background-color: #F1FFF8;
  border-top: 3px var(--O_colorOpaque060) solid;
}

.footer {
  margin-left: 11.5%;
  margin-right: 11.5%;
  border-bottom: 1px var(--V_colorOpaque030) solid;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
}

.titreGras {
  color: var(--V_primaryColor);
  font-weight: bold;
}

.sousTitre {
  color: #637381;
  font-weight: lighter;
}

::-webkit-scrollbar {
  background-color: white;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #33333354;
  border-radius: 50px;
  width: 5px;
}

.loading {
  position: absolute;
  /* postulat de départ */
  top: 50%;
  left: 50%;
  /* à 50%/50% du parent référent */
  transform: translate(-50%, -50%);
  /* décalage de 50% de sa propre taille */
}

.pub {
  background-image: linear-gradient(var(--V_colorOpaque080), var(--V_primaryColor));
}

.pub-text {
  margin: 6%;
  font-size: 200%;
  width: 80%;
  color: white;
  font-weight: bolder;
}

.btn {
  color: white;
  padding: 10px;
  border: none;
  font-weight: bold;
  background-color: var(--O_primaryColor);
  border-radius: 100px;
}

.btn:hover {
  border-radius: 100px;

  animation-name: btn_animation;
  animation-duration: 1s;
  box-shadow: 0px 0px 20px var(--O_colorOpaque060);
}

@keyframes btn_animation {
  from {
    background-color: var(--O_primaryColor);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.0);
  }

  to {
    box-shadow: 0px 0px 20px var(--O_colorOpaque060);
  }
}

.traits {
  position: static;
}

.header-icon {
  border: 1px #0d6639 solid;
  background-color: rgba(13, 102, 57, .05);
  border-radius: 5px;
  width: 35px;
  height: 35px;
  margin-top: 6px;
}

.primaryText {
  color: black;
  font-size: large;
}

.seconderyText {
  color: #637381;
  font-size: 80%;
  font-size: small;
}

.logo {
  height: 50px;
  margin-top: 10px;
}

.nav {
  background-color: var(--Blanc);
  border-radius: 10px;
  padding: 1%;
  box-shadow: 0px 0px 10px rgba(64, 128, 92, .2);
}

.nav-btn {
  outline: none;
  text-decoration: none;
  color: var(--V_primaryColor);
}

.nav-btn:hover {
  border-bottom: 1px var(--V_colorOpaque030) solid;
}

.nav-btn-online {
  font-weight: bold;
  outline: none;
  text-decoration: none;
  color: var(--V_primaryColor);
  padding: .5%;
  border: 3px var(--O_primaryColor) solid;
  border-radius: 10px;
}

.nav-btn-online:hover {
  background-color: var(--O_colorOpaque010);
}

.sousPub {
  background-color: rgba(255, 255, 255, .9);
  border-radius: 10px;
  box-shadow: 0px 0px 30px var(--V_colorOpaque030);
  z-index: 1;
}

.AllSousPub {
  margin-top: -5%;
}

@media screen and (max-width: 800px) {
  .AllSousPub {
    margin-top: 20%;
  }
}

@media screen and (min-width: 800px) {
  .AllSousPub {
    margin-top: -5%;
  }
}



.textgray {
  color: #637381;
  width: 75%;
}

.agence {
  background-color: #F1FFF8;
}

.BoxRegion {
  background-image: linear-gradient(var(--O_colorOpaque060), var(--O_colorOpaque080));
  padding: 2%;
  width: 35%;
  color: white;
}

.produitCard {}

.btn2 {
  color: white;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 3px;
  border: 1px rgba(255, 255, 255, 0.518) solid;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  background-color: white;
  padding: 1%;
  color: var(--V_darkColor);
  border-radius: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.145);
}

.slick-arrow.slick-prev {
  left: -8%;
}

.slick-arrow.slick-next {
  right: -8%;
}

.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:hover {
  background-color: var(--O_primaryColor);
  color: white;
}

.assistanceBox {
  padding: 1%;
  background-image: linear-gradient(rgba(255, 145, 0, 0.05), rgba(255, 145, 0, 0.1));
  border-bottom-right-radius: 60%;
  border-top-left-radius: 10%;
}

.PartenaireBox {
  padding: 1%;
  background-image: linear-gradient(rgba(68, 255, 158, 0.236), #ffffff);
  border-top-left-radius: 100%;
  border-top-right-radius: 10%;
}

.contentAgence {
  width: 50%;
}

.right {
  position: absolute;
  left: 100%;
}

.titrePage {
  background-color: var(--V_primaryColor);
  position: absolute;
  height: 30%;
  width: 100%;
  margin-top: -5%;
  background-image: url('./Fichiers/LogoFiligranne.png');
  
}

.texte_titre {
  position: absolute;
  /* postulat de départ */
  top: 65%;
  left: 50%;
  /* à 50%/50% du parent référent */
  transform: translate(-50%, -50%);
  /* décalage de 50% de sa propre taille */
  color: #F1FFF8;
  width: 100%;
  font-size: 100%;
  text-align: center;
}


.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--O_primaryColor);
  transform-origin: 0%;
  z-index: 1000000;
}

.round {}

.boxCS {
  /* box-shadow: 0px 0px 10px var(--V_colorOpaque010); */
  /* padding: 2%;
  border-left: 5px solid var(--O_colorOpaque060); */
}

.resume {
  box-shadow: 0px 0px 10px var(--V_colorOpaque020);
  padding: 2%;
  border-left: 5px solid var(--V_colorOpaque060);
}

.postuleForm {
  padding: 2%;
  width: 100%;
}

.move {
  animation: mymove 3s infinite;
  position: relative;
  transition: ease-in-out;
}

@keyframes mymove {
  from {
    box-shadow: 0px 0px 50px var(--O_colorOpaque010);
  }

  to {
    box-shadow: 0px 0px 100px var(--O_colorOpaque060);
  }
}

.lien:hover {
  box-shadow: 0px 0px 10px var(--O_colorOpaque030);
  cursor: pointer;
}

.lien:active {
  box-shadow: 0px 0px 10px var(--O_colorOpaque060);
}

.ant-btn-default {
  color: var(--V_primaryColor);
  border: none
}

.link:hover{
  background-color: rgba(107, 107, 107, .07);
}
.link{
  border: 1px #ffa41200 solid;
  padding: 3px;
  margin-top: -3px;
  border-radius: 5px;
}

.list{
  background-color: rgba(68, 68, 68, 0.05);
}

.list:hover{
  background-color: rgba(68, 68, 68, 0.1);
  cursor: pointer;
}

.lien{
  text-decoration: none;
  outline: none;
}

.div_pret:hover{
  cursor: pointer;
  border: 1px #fac46ed0 solid;
}

.warning{
  background-color: var(--O_colorOpaque010);
  box-shadow: none;
}

.btn-file{
  background-color: #f3fdf8;
  width: 98%;
  padding: 1%;
  border-radius: 5px;
  border: 2px #0d6639 solid;
}

.btn-file:hover{
  background-color: #0d66392a;
  width: 98%;
  padding: 1%;
  border-radius: 5px;
  border: 2px #0d6639 solid;
}